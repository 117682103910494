/* eslint-disable @typescript-eslint/unbound-method */
// Disabling the linting on this file due to a known issue: https://github.com/typescript-eslint/typescript-eslint/issues/1929
import { CUSTOM_ELEMENTS_SCHEMA, Component, OnInit, inject, input, output } from '@angular/core'
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms'
import { LogService } from 'src/app/core/services/log.service'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatSelectModule } from '@angular/material/select'
import { MatIconModule } from '@angular/material/icon'
import { CommonModule } from '@angular/common'
import { ButtonModule } from '../button/button.module'
import { NgxMaskDirective } from 'ngx-mask'
import { NgxCurrencyDirective } from 'ngx-currency'
import { I18nService } from 'src/app/core/services/i18n.service'
import { I18nText } from 'src/app/core/interfaces/i18n.service'
import { FormService } from 'src/app/core/services/form.service'
import { ToastService } from 'src/app/core/services/toast.service'
import { GlobalDataService } from 'src/app/core/services/global-data.service'
import { MatCheckboxModule } from '@angular/material/checkbox'


@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styles: [`
    @use "sass:map";
    @import 'src/assets/mixins';
    @import 'src/assets/colors';

    :host {
      --mdc-checkbox-selected-icon-color: var(--chip-background-color, #{map-get($light, primary, main)});
      --mdc-checkbox-selected-hover-icon-color: var(--chip-background-color, #{map-get($light, primary, main)});
      --mdc-checkbox-selected-focus-icon-color: var(--chip-background-color, #{map-get($light, primary, main)});
      --mdc-checkbox-selected-pressed-icon-color: var(--chip-background-color, #{map-get($light, primary, main)});
      --mdc-checkbox-selected-focus-state-layer-color: var(--chip-background-color, #{map-get($light, primary, main)});
      --mdc-checkbox-selected-hover-state-layer-color: var(--chip-background-color, #{map-get($light, primary, main)});
      --mdc-checkbox-selected-pressed-state-layer-color: var(--chip-background-color, #{map-get($light, primary, main)});
    }

    .ContactForm {
      margin: 0 auto;
      width: 100%;
      text-align: right;

      @include mq(medium) {
        max-width: 488px;
      }

      .grid-container {
        padding-left: 0;
        padding-right: 0;
      }

      mat-form-field {
        width: 100%;

        &.mat-form-field-invalid {
          margin-bottom: calc(var(--gutter) / 2);
        }

        input {
          line-height: 1.6rem;
        }
      }

      mat-label {
        line-height: 1.15rem;
      }

      &__form {
        padding: 5px 0 0 0;
      }

      .offer {
        margin-bottom: 12px;
      }
    }
  `],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatInputModule,
    MatCheckboxModule,
    ButtonModule,
    NgxMaskDirective,
    NgxCurrencyDirective,
  ],
  standalone: true,
})
export class ContactFormComponent implements OnInit {
  private _logger = inject(LogService)
  private _formService = inject(FormService)
  private _toastService = inject(ToastService)
  protected globalData = inject(GlobalDataService)
  protected i18n = inject(I18nService)

  submitSuccess = output<void>()
  submitError = output<string>()
  closeForm = output<void>()

  withCloseButton = input(false)
  hideSubmitButton = input(false)
  type = input<'asset' | 'contact'>('contact')
  additionalData = input<Record<string, any>>({})
  successTitle = input<I18nText | string>('Thank You!')
  successBody = input<I18nText | string>('We have received your submission and will be in touch soon!')

  acknowledgementEnabled = input(false)
  customAcknowledgementText = input('')

  contactForm: FormGroup = new FormGroup({})
  disabled: boolean = true
  submitStatus: 'idle' | 'submitting' | 'success' | 'error' = 'idle'

  ngOnInit(): void {
    if (this._formService.isRecaptchaLoaded()) {
      this.disabled = false
    }
    this.contactForm.addControl('first_name', new FormControl({ value: '', disabled: this.disabled }, [
      Validators.required,
    ]))
    this.contactForm.addControl('last_name', new FormControl({ value: '', disabled: this.disabled }, [
      Validators.required,
    ]))
    this.contactForm.addControl('company_name', new FormControl({ value: '', disabled: this.disabled }, [
      Validators.required,
    ]))
    this.contactForm.addControl('email', new FormControl({ value: '', disabled: this.disabled }, [
      Validators.required,
      Validators.email,
    ]))
    this.contactForm.addControl('phone', new FormControl({ value: '', disabled: this.disabled }, [
      Validators.required,
      Validators.minLength(10),
    ]))
    this.contactForm.addControl('message', new FormControl({ value: '', disabled: this.disabled }, []))
    if (this.type() === 'asset') {
      this.contactForm.addControl('offer_price_native', new FormControl({ value: null, disabled: this.disabled }, []))
      this.contactForm.addControl('offer_currency', new FormControl({ value: this.globalData.selectedCurrency().code, disabled: this.disabled }, []))
    }
    if (this.acknowledgementEnabled()) {
      this.contactForm.addControl('acknowledgement_agreement', new FormControl({ value: false, disabled: this.disabled }, []))
    }
  }

  get firstName() { return this.contactForm.get('first_name') }
  get lastName() { return this.contactForm.get('last_name') }
  get company() { return this.contactForm.get('company_name') }
  get offer() { return this.contactForm.get('offer_price_native') }
  get email() { return this.contactForm.get('email') }
  get phone() { return this.contactForm.get('phone') }
  get message() { return this.contactForm.get('message') }
  get acknowledgement_agreement() { return this.contactForm.get('acknowledgement_agreement') }

  submit(): void {
    Object.values(this.contactForm.controls).forEach(control => {
      control.markAsTouched() // causes form to validate
    })
    if (this.contactForm.invalid) { return }
    this._logger.debug('submitting contact form: ', this.contactForm.value)
    this.submitStatus = 'submitting'
    const formData = {
      ...this.contactForm.value,
      ...this.additionalData(),
      form_type: this.type(),
      page_url: window.location.href,
    }
    if (this.acknowledgementEnabled()) {
      formData.acknowledgement_agreement_label = this.customAcknowledgementText()
    }
    this._formService.submitContactForm(formData).subscribe((response) => {
      if (response.success) {
        this.contactForm.reset()
        this._logger.debug('contact form submitted successfully')
        this.submitSuccess.emit()
        this.submitStatus = 'success'
      } else {
        this._toastService.error({
          message: 'There was an error submitting your form. Please try again.',
          includeClose: true,
        })
        this._logger.error(`action: submitContactForm, response_status: ${response.success}, response_message: ${response.reason}`)
        this.submitError.emit(response.reason)
        this.submitStatus = 'error'
      }
    })
  }
}
