import { Component, OnInit, inject, input } from '@angular/core'
import { BadgeService } from 'src/app/core/services/badge.service'
import { CommonModule } from '@angular/common'
import { MatIconModule } from '@angular/material/icon'
import { IAsset } from 'src/app/core/interfaces/asset.interface'
import { LabelComponent, LabelModifier, LabelType } from '../label/label.component'
import { IBadgeSetting } from 'src/app/core/interfaces/badge.interface'

@Component({
  selector: 'app-badges-container',
  template: `
    @if (enabled()) {
      @for (badge of badges; track badge) {
        <app-label
          [type]="labelType()"
          [modifier]="labelModifier()"
          [leftIcon]="badge.icon"
          [outlinedIcon]="true"
          [text]="badge.label"
        ></app-label>
      }
    }
  `,
  styles: [`
    :host {
      display: flex;
      gap: 10px;
    }
  `],
  imports: [CommonModule, LabelComponent, MatIconModule]
})
export class BadgesContainerComponent implements OnInit {
  private _badgeService: BadgeService = inject(BadgeService)

  enabled = input(false)
  asset = input<IAsset>()
  labelType = input<LabelType>(LabelType.reversed)
  labelModifier = input<LabelModifier>(LabelModifier.secondary)

  badges: IBadgeSetting[] = []

  ngOnInit(): void {
    this.badges = this._badgeService.getBadgesForData(this.asset())
  }
}
